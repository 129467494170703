<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card
        v-if="userData"
      >
        <validation-observer
          ref="userRules"
        >
          <!-- form -->
          <b-form>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                  />
                  <h4 class="ml-1 mb-0 font-weight-normal">
                    Admin Information
                  </h4>
                </div>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="First Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Last Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="userData.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="!adminId"
                sm="4"
              >
                <b-form-group
                  label="Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required|min:6"
                  >
                    <b-form-input
                      v-model="userData.password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Phone Number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone Number"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Instances"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Instances"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedInstances"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="name"
                      multiple
                      :options="instancesOptions"
                      :reduce="option => option.id"
                      :class="{'is-invalid': errors.length > 0 }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.role_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="display_name"
                      multiple
                      :options="rolesOptions"
                      :reduce="option => option.id"
                      :class="{'is-invalid': errors.length > 0 }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="saveChanges"
                >
                  Save
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  :to="{ name: 'admin-users'}"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <!--/ form -->
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, min } from '@validations'
import Ripple from 'vue-ripple-directive'
import useAdminsProfile from '@/views/admin/admins/admin-profile/useAdminsProfile'
import useAdminsList from '@/views/admin/admins/admins-list/useAdminsList'

import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      adminId: this.$router.currentRoute.params.id || null,
      userData: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        role_id: null,
        schools_data_multiselect: [],
      },
      selectedInstances: [],
      instancesOptions: [],
      rolesOptions: [],
      required,
      email,
      min,
    }
  },
  watch: {
    selectedInstances(val) {
      this.userData.schools_data_multiselect = val.map(item => ({
        id: item,
      }))
    },
  },
  setup() {
    const {
      createAdmin,
      fetchAdmin,
      updateAdmin,
      fetchAdminRolesList,
    } = useAdminsProfile()

    const {
      fetchInstancesList,
    } = useAdminsList()

    return {
      createAdmin,
      fetchAdmin,
      updateAdmin,
      fetchInstancesList,
      fetchAdminRolesList,
    }
  },
  async created() {
    this.isLoading = true

    if (this.adminId) {
      await this.setAdminData()
    }

    this.instancesOptions = await this.fetchInstancesList()
    this.rolesOptions = await this.fetchAdminRolesList()
    this.isLoading = false
  },
  methods: {
    async setAdminData() {
      const response = await this.fetchAdmin(this.adminId)

      this.userData = {
        ...response,
        schools_data_multiselect: response.schools.map(item => ({ id: item.id })),
      }

      this.selectedInstances = response.schools.map(item => item.id)
    },
    saveChanges() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.adminId) {
              this.updateAdminProfile()
            } else {
              this.createAdminProfile()
            }
          } else {
            reject()
          }
        })
      })
    },
    async createAdminProfile() {
      await this.createAdmin(this.userData).then(() => {
        this.$router.push({ name: 'admin-users' })
      })
    },
    async updateAdminProfile() {
      await this.updateAdmin(this.userData).then(() => {
        this.$router.push({ name: 'admin-users' })
      })
    },
  },
}
</script>

<style lang="scss">

</style>
