import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserProfile() {
  // Use toast
  const toast = useToast()

  const fetchAdmin = id => axios
    .get(`/auth/admins/${id}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Admin Info',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchAdminRolesList = queryParams => axios
    .get('/auth/admin-roles', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Admin Roles',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const createAdmin = queryParams => axios
    .post('/auth/admins', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New User was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      if (error.response.data.errors?.email) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.errors.email[0],
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating user item',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    })

  const updateAdmin = queryParams => axios
    .put(`/auth/admins/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'User was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating admin',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    createAdmin,
    fetchAdmin,
    updateAdmin,
    fetchAdminRolesList,
  }
}
